<template>
  <div :class="$style.body">
    <el-pagination background
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      :current-page="now"
      :page-sizes="[20, 50, 100, 500]"
      :page-size="pageSize"
      @size-change="changeSize"
      @current-change="change">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    total: Number,
    now: {
      default: () => 1
    },
    pageSize: Number
  },

  methods: {
    change(val) {
      this.$emit('update:now', val)
    },
    changeSize(val) {
      this.$emit('changeSize', val)
    },
  }
}
</script>

<style module lang="scss">
.body {
  padding-top: 10px;
  text-align: center;
}
</style>