<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  // created() {
  //   if (localStorage.token) {
  //     this.$store.commit('LOGIN_IN', { token: localStorage.token })
  //   } else {
  //     this.$router.push('/login')
  //   }
  // }
}
</script>

<style>
</style>
