import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/views/Layout'
import Login from '@/views/Login'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export const constantRouterMap = [
  { path: '/login', name: 'login', component: Login, hidden: true },
  {
    path: '/',
    component: Layout,
    children: [
      
      {
        path: '/Monitor',
        name: 'Monitor',
        component: () => import('@/views/Monitor'),
        meta: { title: '保单列表' ,icon: require('@/assets/-svg/order.svg')},
        alias: '/'
      },
      {
        path: '/group',
        name: 'group',
        component: () => import('@/views/group/group'),
        meta: { title: '团品列表' ,icon: require('@/assets/-svg/xiangmu.svg')},
        alias: '/'
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@/views/group/team'),
        meta: { title: '团队列表' ,icon: require('@/assets/-svg/agent.svg')},
        alias: '/'
      },
      {
        path: '/person',
        name: 'person',
        component: () => import('@/views/group/person'),
        meta: { title: '个人列表' ,icon: require('@/assets/-svg/yuangong.svg')},
        alias: '/'
      },
      {
        path: '/area',
        name: 'area',
        component: () => import('@/views/group/area'),
        meta: { title: '发出统计' ,icon: require('@/assets/-svg/goods.svg')},
      },
      {
        path: '/sendBack',
        name: 'sendBack',
        component: () => import('@/views/group/sendBack'),
        meta: { title: '寄回统计' ,icon: require('@/assets/-svg/area.svg')},
      },
      
      {
        path: '/payChart',
        name: 'payChart',
        component: () => import('@/views/payChart/payChart'),
        meta: { title: '理赔趋势' ,icon: require('@/assets/-svg/caiwu.svg')},
      },
      // {
      //   path: '/performanceChart',
      //   name: 'performanceChart',
      //   component: () => import('@/views/payChart/performanceChart'),
      //   meta: { title: '业绩趋势' ,icon: require('@/assets/-svg/xiangmu.svg')},
      // },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test/test'),
        meta: { title: '产品类别', hidden: true ,icon: require('@/assets/-svg/key.svg')},
      },
    ],
    
  },
] 

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

