<template>
  <div>
    <div :class="$style.logo">
      <div>
        <img src="../assets/logo.png" :class="$style.img" />
      </div>
    </div>
    <el-menu
      :default-active="$route.name"
      @select="select"
      router
      background-color="#2d3037"
      text-color="#fff"
      active-text-color="#409eff"
      :collapse="isCollapse"
      :collapse-transition="false"
    >
      <template>
        <div v-for="(item, index) in navList" :key="index">
          <el-menu-item :index="item.name" v-if="!item.meta.hidden" @click.native="changeindex(index)">
            <img v-if="item.meta.icon" :src="item.meta.icon" :class="$style.navIcon">
            <span slot="title">{{item.meta.title}}</span>
          </el-menu-item>
        </div>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { constantRouterMap } from '@/utils/router'
export default {
  computed: {
    isCollapse() {
      return this.$store.state.isNavShrink;
    },
    active() {
      return constantRouterMap[1].children;
    },
    navList() {
      return constantRouterMap[1].children;
    }
  },
  watch: {
    active() {
      return this.$route.path;
    },
    navList() {
      return this.$store.getters.permission_routers[2].children;
    }
  },
  mounted() {},
  methods: {
    changeindex(index) {
      
    },
    select(index) {
      this.$router.push({
        name: index
      });
    }
  }
};
</script>

<style module lang="scss">
.navIcon {
  width: 15px;
  height: 15px;
  margin-right: 20px;
}

.logo {
  margin: 40px 20% 30px 20%;
}
.img {
  width: 100%;
  height: 100%;
}
</style>

