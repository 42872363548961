<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      class="login-form"
      :rules="rules"
      label-position="left">
      <h3 class="title">运 费 险 实 时 监 控</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" placeholder="用户名"/>
      </el-form-item>

      <el-form-item prop="password">
        <el-input
          :type="pwdType"
          v-model="loginForm.password"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        />
      </el-form-item>

      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >登 录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  created(){
  },
  data() {
    return {
      loginForm: {
        username: "",
        password: ""
      },

      loading: false,
      pwdType: "password",

      rules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" }
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }]
      }
    };
  },

  methods: {

    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
    },

    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          this.login();
        } else {
          return false;
        }
      });
    },

    login() {
      // if(this.loginForm.username == 'admin' && this.loginForm.password == 'haituan20210505') {
      //   this.$emit('changeLogin', false)
      //   localStorage.setItem('login', true)
      // } else {
      //   this.$message.error('用户名或密码错误')
      // }
      // this.loading = false
      this.$http.post("/insured_login", {
        username: this.loginForm.username,
        password: this.loginForm.password
      })
      .then(res => {
        if (res.msg == "登录成功") {
          this.$store.commit('LOG_IN', {
            token: res.data.token
          })
        }
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;
$bg: #2d3a4b;

.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  overflow-y: auto;
  
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 600px;
    max-width: 100%;
    min-height: 400px;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    background-color: $bg;
    z-index: 1000;
  }

  .title {
    font-size: 26px;
    font-weight: 400;
    color: $light_gray;
    margin: 40px auto;
    text-align: center;
    font-weight: bold;
  }
}
.login-container {
  /deep/ .el-input {
    height: 47px;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }

  /deep/ .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>
