import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import { getCookie, setCookie, delCookie } from './cookie'
Vue.use(Vuex)

let token = getCookie('token')
let isLogin = token ? true : false

export default new Vuex.Store({
  state: {
    username: '',
    userGrade: '',
    token: token,
    isLogin: isLogin,
    isNavShrink: false,
    role: ''
  },
  mutations: {
    LOG_IN(state, { token = '' }) {
      state.isLogin = true
      state.token = token
      setCookie('token', token)
      router.push('/')
    },

    LOG_OUT(state) {
      state.isLogin = false
      state.token = ''
      state.role = ''
      delCookie('token')
      router.push({
        name: 'login'
      })
      // location.reload();
    },
    SET_ROLES: (state, role) => {
      state.role = role
    },
    // 导航 展开/收缩
    SCROLL_NAV(state) {
      state.isNavShrink = !state.isNavShrink
    }
  }
})
