import axios from "axios";
import Vue from "vue";
import store from "./store";
import { Message } from 'element-ui'
import { getCookie } from "./cookie";

const request = axios.create({
  // headers: {
  //     'content-type': 'application/x-www-form-urlencoded'
  //   },
  baseURL: process.env.NODE_ENV === 'development' ? 'https://mng.htchaxun.cn' : 'https://mng.htchaxun.cn'
})
request.interceptors.request.use(
  config => {
    console.log(store.state);
    // 登录后所有请求附带token
    if (store.state.isLogin) {
      // config.headers.xx_token = getCookie('token')
      config.headers['xx-token'] = getCookie('token')

    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
request.interceptors.response.use(
  res => {
    if(res.data.code == 1) {
      return res.data
    } else if(res.data.code == 3) {
      store.commit('LOG_OUT')
      Message.error(res.data.msg)
      return Promise.reject(res.data)
    } else {
      Message.error(res.data.msg)
      return Promise.reject(res.data)
    }
  },
  err => {
    return Promise.reject(err)
  }
)
Vue.prototype.$http = request
