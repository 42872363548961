import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "@/utils/request";
import router from "@/utils/router";
import store from "@/utils/store";
import echarts from 'echarts'
import BasePagination from '@/components/BasePagination'
Vue.component('BasePagination',BasePagination)

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
